import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'

const Overlay = ({
  children,
  isOverlayOpen,
  setIsOverlayClose,
  zIndex = 50
}) => {
  const handleOverlayClose = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      setIsOverlayClose()
    }
  }

  if (!isOverlayOpen) return null

  return (
    <AnimatePresence>
      <motion.div
        onClick={handleOverlayClose}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0 }}
        className={`fixed top-0 left-0 modal-overlay inset-0 w-screen h-screen z-[${zIndex}] `}
        style={{
          background: 'rgba(15, 22, 37, 0.70)',
          backdropFilter: 'blur(10px)'
        }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}

export default Overlay
