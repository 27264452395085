

import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react-lite'
import telegramStore from '@store/telegram'
import Spinner from '@components/Spinner'
import * as tapi from '@api/telegram'
import Pin from '@tg/assets/Pin'


const MemberModal = React.memo(({ member }) => {
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)

	const addToWl = () => {
		// if (type == 'group') {
		// 	tapi.addChildToWatchlist({ telegramId: id })
		// 		.then((res) => console.log(res))
		// 		.catch((e) => console.error(e))
		// } else 
		{
			tapi.addParentToWatchlist({ telegramId: member.user_id })
				.then((res) => console.log(res))
				.catch((e) => console.error(e))
		}
	}
	
	const Group = observer(({ group, member }) => {
		// const onClick = () => {
		// 	telegramStore.setActiveTab(Tabs.find((tab) => tab.key === 'chat'))
		// 	telegramStore.setActiveChat(group)
		// }
		
		if (!group) return
		console.log(member)
		
		return (
			<div
				onClick={() => telegramStore.onChatClick(group)}
				className="gap-4 items-center flex flex-row w-full"
			>
				<img
					className="h-8 w-8 rounded-full"
					alt={group.title} // Added alt attribute for accessibility
					src={`${process.env.REACT_APP_IMAGES_SERVER}/imgs/groups/${group.id}.jpg`}
				/>
				<div className={`hover:scale-[1.05] ${member.admin ? 'font-bold text-red-600' : ''}`}>{group.title}</div>
			</div>
		)
	})

	const GroupList = observer(({ groups }) => {
		if (!groups || !groups.length) return <div>no groups</div>

		return (
			<div className="flex flex-col w-full items-center overflow-auto">
				<div className="flex flex-row gap-2 items-center pb-4">
					{/* Optional icons or titles can be placed here */}
				</div>
				<div className="flex flex-col gap-2 items-left w-full">
					{groups.map((group) => (
						<Group key={group.id} group={group.group} member={group} />
					))}
				</div>
			</div>
		)
	})

	useEffect(() => {
		tapi.parentGroups({ parentId: member.user_id })
			.then((res) => setData(res.data.data))
			.catch((e) => console.error(e))
			.finally(() => setLoading(false))
	}, [])

	if (!loading && !data?.length) return <div>no groups found.</div>
	return (
		<div className="h-[600px] overflow-hidden inner-bg-color text-white w-[350px] rounded-lg flex flex-col px-4 py-4 no-scrollbar">
			{/* Header remains fixed */}
			<div className="mb-4 flex flex-row gap-2 items-center justify-between pr-2">
				<div className="text-[20px] font-semibold">
					{member.username || member.first_name || member.last_name} - Groups
				</div>
				<button onClick={addToWl}>
					<Pin />
				</button>
			</div>

			{/* Scrollable List */}
			<div
				style={{ height: 'calc(100% - 64px)' }}
				className="overflow-auto"
			>
				{/* <div className="text-[18px] font-semibold">
					Groups joined
				</div> */}
				{/* Adjust '64px' based on the actual height of the header */}
				{loading ? <Spinner /> : <GroupList groups={data} />}
			</div>
		</div>
	)
})

export default MemberModal