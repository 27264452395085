import React, { useEffect, useState } from 'react'

const UserImage = ({ user }) => {
	const [isImageLoaded, setImageLoaded] = useState(false) // Track if image has been loaded

	// Set image as loaded when it comes into view for the first time
	if (!isImageLoaded) {
		setImageLoaded(true)
	}

	if (!user) return
	return (
		<div className="flex gap-2 items-center text-blue-500">
			<img
				className="h-8 w-8 rounded-full"
				src={`${process.env.REACT_APP_IMAGES_SERVER}/imgs/users/${user?.id}.jpg`}
				alt={`${user.first_name || user.username}'s profile`}
				onLoad={() => setImageLoaded(true)} // Ensure image is marked as loaded
			/>
			<div>{user.username}</div>
		</div>
	)
}

export default UserImage