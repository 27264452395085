import React from 'react'
import { IconMap } from './IconMap'

function Socials({ data, className='w-6 h-6' }) {
  if (!data) return null

  const dexscreener = data.dexscreener?.pairs?.length
    ? data.dexscreener?.pairs[0]
    : null

  const Mapping = (props) => {
    const Icon = IconMap[props.name]
    return <Icon { ...props} />
  }

  return (
    <div className="flex flex-row gap-2">
      {dexscreener && (
        <a
          href={dexscreener.url}
          title="dexscreener"
          target="_blank"
          rel="noopener noreferrer"
          className="text"
        >
          <Mapping name='dexscreener' className={className} />
          {/* {IconMap['dexscreener']} */}
        </a>
      )}
      {data.social &&
        Object.entries(data?.social).map(([key, value]) => {
          if (value && key != 'socials') {
            // && key != 'website'
            return (
              <a
                key={key}
                href={value.expanded_url}
                title={key}
                target="_blank"
                rel="noopener noreferrer"
                className="social-bg text"
              >
                <Mapping name={key} className={className} />
                {/* {IconMap[key]} */}
              </a>
            )
          }
        })}
    </div>
  )
}

export default Socials
