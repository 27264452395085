
import Sidebar from "../TelegramPlayground/Sidebar"
import ChatLayout from "../TelegramPlayground/ChatLayout"
import Activity from "../TelegramPlayground/Activity"
import { observer } from 'mobx-react-lite'
import telegramStore from '@store/telegram'
import historyStore from "../../stores/history"

const InnerLayout = observer(() => {
	// const onChatClick = (chat) => {
    //     if (telegramStore.getActiveChat()?.id == chat?.id) return
	// 	historyStore.push({
	// 		tab: telegramStore.getActiveTab(),
	// 		chat: telegramStore.getActiveChat(),
	// 		details: telegramStore.getActiveDetails(),
	// 		child: telegramStore.getActiveChild()
	// 	})

	// 	telegramStore.setActiveDetails(null)
	// 	telegramStore.setActiveChat(chat)
	// 	telegramStore.setActiveChild(undefined)
	// 	// setActiveChat(chat)
	// }

    return (
        telegramStore.tabType === 'activity' ? <Activity /> :
        <div id="tg-inner-layout" className="flex flex-row w-full">
            <Sidebar onChatClick={telegramStore.onChatClick} />
            <ChatLayout />
            {/* {renderTab()} */}
        </div>
    );
    
})

export default InnerLayout
