import React, { useState, useEffect } from 'react';

function ImageLoader(props) {
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchImage();
    }, 5000); // retry every 5 seconds

    return () => clearInterval(intervalId);
  }, []);

  const fetchImage = () => {
    const path = props.src;
    console.log(path)
    fetch(path)
      .then(response => {
        if (!response.ok) throw new Error('Not found');
        return response.blob();
      })
      .then(blob => {
        setImageUrl(URL.createObjectURL(blob));
        setLoading(false);
        clearInterval(intervalId);
      })
      .catch(error => {
        // console.error('Error loading image:', error);
        setError(true);
      });
  };

  return (
    <img
      className={props.className}
      src={imageUrl}
      alt={props.alt}
      onError={() => {
        setError(true);
        setLoading(false);
      }}
    />
  );
}

export default ImageLoader;
