import React from 'react'
import GroupDetails from './GroupDetails'
import ProfileDetails from './ProfileDetails'
import { observer } from 'mobx-react-lite'

const ChatDetails = observer((props) => {
	//   if (!members) return
	return (
		<div className="h-full w-[350px]">
			{['profiles', 'myprofiles'].includes(props.activeTab.key) ? (
				<ProfileDetails />
			) : (
				<GroupDetails group={props.chat}  />
			)}
		</div>
	)
})

export default ChatDetails
