import ProfilesIcon from './assets/Profiles'
import ChatIcon from './assets/Chat'
import GroupsIcon from './assets/Groups'
import WlProfilesIcon from './assets/WlProfiles'

export const Tabs = [
	{
		label: 'Chats',
		key: 'chat',
		icon: <ChatIcon />
	},
	{
		label: 'Activity',
		key: 'activity',
		icon: <ProfilesIcon />
	},
	{
		label: 'Profiles',
		key: 'profiles',
		icon: <ProfilesIcon />
	},
	{
		label: 'My Groups',
		key: 'mygroups',
		icon: <GroupsIcon />
		
	},
	{
		label: 'My Profiles',
		key: 'myprofiles',
		icon: <WlProfilesIcon />
	},
	{
		label: 'My Feed',
		key: 'myfeed',
		icon: <WlProfilesIcon />
	}
]