import { useState, useEffect } from 'react'

const SM_SCREEN = 768
const MD_SCREEN = 1150
const LG_SCREEN = 1600
const XL_SCREEN = 2100

const useMobile = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)

  useEffect(() => {
    const updateInnerWidth = () => setInnerWidth(window.innerWidth)

    window.addEventListener('resize', updateInnerWidth)

    return () => window.removeEventListener('resize', updateInnerWidth)
  }, [])

  return {
    innerWidth,
    isSmScreen: innerWidth < SM_SCREEN,
    isMdScreen: innerWidth > SM_SCREEN && innerWidth < LG_SCREEN,
    isLgScreen: innerWidth > MD_SCREEN && innerWidth < XL_SCREEN,
    isXlScreen: innerWidth > LG_SCREEN
  }
}

export default useMobile
