import React, { useState, useEffect } from 'react'
import MetaMaskLogin from './provider'

const Login = () => {

    // useEffect(() => {
    //     if (!message ) return
    //     aapi.getMessage()
    //     .then(res => setMessage(res.data.data))
    //     .catch(e => console.error(e))
    // }, [])


	return <MetaMaskLogin />
}

export default Login
