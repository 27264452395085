import React from 'react'
import { useInView } from 'react-intersection-observer'
import Group from '@tg/components/Group'
import useMobile from '@hooks/useMobile'

const Message = ({ message, showUsername, messageRef, showImage, group=null }) => {
	const [viewRef, inView] = useInView()
	const { isSmScreen } = useMobile()
	if (!message.user) return

	// console.log(message.user)
	const getUsername = () => {
		if (message.user.channel) return `${message.group.title}`
		return `${message.user.first_name} ${message.user.last_name || ''}`.substring(0, )
	}
	
	return (
		<div className={` flex flex-col w-full`} ref={messageRef}>
			<div ref={viewRef} className="h-full w-full">
				{/* {message.reply && (
        <div className="message.text-xs message.text-gray-500 pl-2">
        message.reply to {message.reply.message.username}
        </div>
      )} */}
				<div
					className={`flex ${
						message.user.isOwn ? 'justify-end' : 'justify-start'
					}`}
				>
					<div
						className={`flex flex-col w-full ${
							!(inView || messageRef) && 'min-h-[20px]'
						}  ${message.user.isOwn ? 'items-end' : 'items-start'}`}
					>
						{true && (
							<div className="flex flex-row items-end w-full gap-2">
								{showImage ? (
									<img
										className="h-8 w-8 rounded-full min-w-[30px]"
										src={`${process.env.REACT_APP_IMAGES_SERVER}/imgs/${message.user.channel ? `groups/${message.group_id}` : `users/${message.user.user_id}`}.jpg`}
										alt={message.user.first_name?.substring(0, 1)}
										// onLoad={() => setImageLoaded(true)} // Ensure image is marked as loaded
									/>
								) : (
									<div className="pl-8"></div>
								)}

								<div
									className={`${
										message.user.isOwn
											? 'bg-blue-700'
											: 'bg-[#182533]'
									} text-white p-2 rounded-lg max-w-xs lg:max-w-md text-[13px] md:text-[16px] `}
								>
									{showUsername && (
										<div
											className={`  mb-1 ${
												message.user.admin
													? 'text-red-500'
													: 'text-green-400'
											} font-bold flex flex-row gap-5 items-center`}
										> {isSmScreen ?

											<div className='flex flex-col gap-1'>

												<div className="flex flex-row gap-2 items-center ">
													{getUsername()}
												</div>
												{group ? 
												<div className='flex flex-row gap-5 items-center '>
													<p className='text-white whitespace-nowrap opacity-70 text-purple-500'>-></p>
													<Group group={message.group} /> 
													
													</div>
													: null

												}
											</div>
											: 
											<>
											<div className="flex flex-row gap-2 items-center ">
												{getUsername()}
											</div>
											{group ? 
											<div className='flex flex-row gap-5 items-center '>
												<p className='text-white whitespace-nowrap opacity-70 text-purple-500'>-></p>
												<Group group={message.group} /> 
												
												</div>
												: null

											}
											</>

										}

										</div>
									)}
									<div className="flex flex-row gap-2 items-center">
										<div className="flex flex-row flex-grow gap-3 items-end">
											<div className={`flex-grow ${message.deleted ? 'line-through' : ''}`}>
												{message.text}
											</div>
											<div className="flex justify-end ">
												<span className="opacity-50  text-[13px] whitespace-nowrap">
													{new Date(
														message.msg_timestamp *
															1000
													)
														.toString()
														.substring(16, 21)}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Message
