import { observer } from 'mobx-react-lite'
import telegramStore from '@store/telegram'
import { Tabs } from '../consts'
import * as helpers from '@api/helpers'
import Group from '@tg/components/Group'
import UserImage from '@tg/components/UserImage'
import { useInView } from 'react-intersection-observer'

// const Profile = ({ user }) => {
// 	return (
// 		<div className="text-blue-500">
// 			<img />
// 			{user?.username} </div>

// 	)
// }

const JoinRow = ({ join, lastChildRef }) => {
	if (!join?.group) return
	const [viewRef, inView] = useInView()

	return (
		<div
			ref={lastChildRef}
			className={`${'bg-[#182533]'} mx-auto text-white py-2 px-1 rounded-full max-w-xs lg:max-w-xl `}
		>
			<div
				ref={viewRef}
				className="flex flex-row mx-5 font-bold whitespace-nowrap font-bold "
			>
				<div className="flex flex-row  justify-between w-full">
					<div className="flex flex-row flex-grow gap-5 items-end justify-start ">
						<div className="min-w-[160px]">
							<UserImage user={join.parent} />
						</div>
						<div className="mx-2 font-bold my-auto">joined </div>
						<Group group={join.group} className={"max-w-[160px] overflow-hidden"} />
					</div>

					<div className=" pt-2">
						<span className="ml-3 opacity-50  text-[13px]">
							{new Date(join.timestamp * 1000)
								.toString()
								.substring(16, 21)}
						</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default JoinRow
