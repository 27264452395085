import { action, makeAutoObservable, makeObservable, observable, toJS } from 'mobx'

class History {

    constructor() {
        this.history = []
        this.currentPosition = 0
        this.stackLength = 0
        makeAutoObservable(this)
    }

    push = action((state, type) => {
        // if (type == 'back') return
        // console.log(action)

        this.history = this.history.slice(0, this.stackLength)
        this.history.push(state)
        this.stackLength = this.history.length
        this.currentPosition = this.stackLength - 1
        this.print()
    })


    pushLast = action((state, type) => {
        const lastState = this.getLastState()
        
        if (state?.tab !== lastState.tab || state?.chat?.id !== lastState.chat?.id) {

            this.history.push(state)
            this.currentPosition++
            this.stackLength = this.history.length
            // this.print()
        }
        // console.log('stack :', this.stackLength)
    })

    next = action(() => {

        // console.log(this.currentPosition, this.stackLength)
        if (this.currentPosition < this.stackLength - 1) {
            this.currentPosition++;
        }
        // this.print()

        return this.history[this.currentPosition];
    });

    prev = action((dec) => {

        if (this.currentPosition > 0 && dec) {
            this.currentPosition--;
        }
        const state = this.history[this.currentPosition];
        
        // this.print()
        return state
    });

    print() {
        // console.log('stack :', this.stackLength)
        // console.log(toJS(this.history[this.history.length - 1]?.chat?.username))
        // console.log(toJS(this.history[this.currentPosition]?.tab))
    }

    getLastState = () => {
        return this.history[this.history.length - 1]
    }

    isLastPosition = () => {
        return this.currentPosition == this.stackLength - 1
    }
}

export default new History()