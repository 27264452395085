import BaseModal from './BaseModal'
import React, { useEffect, useState } from 'react'
import telegramStore from '@store/telegram'
import { observer } from 'mobx-react-lite'

const memberSettings = [
	{
		title: 'Max members',
		value: 'On',
		icon: '🔒',
		key: 'maxMembers',
		type: 'number'
	},
	{
		title: 'Has user',
		value: 'Off',
		icon: '🕒',
		key: 'hasMember',
		type: 'text'
	}
	// ... Add all other settings here
]

const sortSettings = [
	{ title: 'Newest', value: 'On', icon: '🔒', key: 'latest' },
	{ title: 'Messages', value: 'On', icon: '🔒', key: 'messages' },
	{ title: 'Key Profiles', value: 'Off', icon: '🕒', key: 'key_profiles' },
	{ title: 'Members', value: 'Off', icon: '🕒', key: 'members' }
	// ... Add all other settings here
]

const SettingsWrapper = observer(({ settings }) => {
	const [inputValue, setInputValue] = useState('')

	// // Function to handle input changes
	// const handleInputChange = (event) => {
	// 	setInputValue(event.target.value);
	// };

	return (
		<div>
			{settings.map((setting, index) => {
				// State hook for each input, initializing with an empty string or a predefined value
				const [inputValue, setInputValue] = useState('')

				// Function to handle input changes
				const handleInputChange = (event) => {
					setInputValue(event.target.value)
				}

				useEffect(() => {
					telegramStore.setGroupsQuery({
						...telegramStore.getGroupsQuery(),
						[setting.key]: inputValue
					})
				}, [inputValue])

				return (
					<div
						key={index}
						className="flex gap-4 justify-between items-center py-2 text-sm"
					>
						<div className="flex items-center space-x-4">
							<span>{setting.icon}</span>
							<span>{setting.title}</span>
						</div>
						<input
							className={`flex-grow p-2 bg-[#242f3d] max-w-[130px]  text-white placeholder-gray-400 Squad-font placeholder:text-sm placeholder:pl-2 rounded-[50px] 
							 `}
							placeholder="" // Placeholder text for the input
							value={inputValue} // Binding state to the input
							onChange={handleInputChange} // Handling changes
							type={setting.type}
						/>
					</div>
				)
			})}
		</div>
	)
})

const RadioButton = ({ setting, handleOnChange, checked }) => {
	return (
		<label className="inline-flex items-center">
			<input
				type="radio"
				className="form-radio text-light-blue-500 border-gray-300 focus:ring-light-blue-500"
				checked={checked?.title == setting.title}
				onChange={() => handleOnChange(setting)}
			/>
			<span className="ml-2">{setting.label}</span>
		</label>
	)
}

const SortSettings = observer(({ settings }) => {
	const [checked, setChecked] = useState(null)

	const handleOnChange = (setting) => {
		setChecked(setting)
		telegramStore.setGroupsQuery({
			...telegramStore.getGroupsQuery(),
			sort: setting.key
		})
	}

	return (
		<div className="py-2">
			{settings.map((setting, index) => (
				<div
					key={index}
					className="flex justify-between items-center py-2 text-sm "
				>
					<div className="flex items-center space-x-4">
						<span>{setting.icon}</span>
						<span>{setting.title}</span>
					</div>
					<div className="text-gray-400">
						<RadioButton
							setting={setting}
							checked={checked}
							handleOnChange={handleOnChange}
						/>
					</div>
				</div>
			))}
		</div>
	)
})

const Category = ({ text }) => {
	return <div className="font-bold text-[#55b0f0]">{text}</div>
}

const FilterModal = () => {
	return (
		<div className="h-[600px] ">
			<div className="px-4 py-4 inner-bg-color text-white w-80 rounded-lg overflow-hidden">
				<h1 className="text-2xl font-bold pb-3">Settings</h1>
				<div className="flex flex-col gap-2">
					<Category text="Members" />
					<SettingsWrapper settings={memberSettings} />
					<Category text="Sort" />
					<SortSettings settings={sortSettings} />
				</div>
			</div>
		</div>
	)
}

const Filters = () => {
	return <BaseModal element={FilterModal} />
}

export default Filters
