import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as tapi from '@api/telegram'
import Header from '../components/Header'
import { observer } from 'mobx-react-lite'
import telegramStore from '@store/telegram'
import { Tabs } from '../consts'
import Joins from '../components/Joins'
import Messages from './Messages'
import useMobile from '@hooks/useMobile'

const Activity = observer(() => {
	// if (!data) {
	// 	return <div className="bg-[#0e1621] h-full w-full">no results</div>
	// }

	const { isSmScreen } = useMobile()

	const Header = ({ text }) => {
		return (
			<div className="flex mb-1 p-1  inner-bg-color font-bold text-lg flex-col m-auto items-center justify-center">
				{text}
			</div>
		)
	}

	return (
		<div
			id="tg-inner-layout"
			className="flex flex-row w-full scrollbar-webkit"
		>
			<div
				id="tg-activity-layout"
				className="w-full h-full flex flex-row relative max-h-[1200px]"
				style={{ boxShadow: '0 2px 0px rgba(0, 0, 0, 0.1)' }}
			>
				<div className="flex flex-col w-full h-full max-h-full overflow-auto">
					{isSmScreen ? (
						<div className="bg-white shadow-lg rounded-lg p-2 max-h-full overflow-hidden pb-5">
							<Header text={'Messages'} />
							<Messages />
						</div>
					) : (
						<div className="w-full h-full grid grid-cols-2 gap-4 px-4 pt-2 ">
							<div className="bg-white rounded-lg p-2 max-h-full overflow-auto overflow-hidden pb-5">
								<Header text={'Joins'} />
								<Joins />
							</div>
							<div className="bg-white shadow-lg rounded-lg p-2 max-h-full overflow-hidden pb-5">
								<Header text={'Messages'} />
								<Messages />
							</div>
						</div>
					)}
				</div>
				{/* Consider uncommenting and using the Header if needed */}
				{/* <Header id={} title={'Your Title Here'} /> */}
			</div>
		</div>
	)
})

export default Activity
