import axios from 'axios'

const http = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
  withCredentials: true
})


http.defaults.withCredentials = true
http.withCredentials = true

// Add a response interceptor
http.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // user.setCsrfToken(response.headers['x-csrf-token'])
    // http.csrfToken = response.headers['x-csrf-token']
    return response
  },
  
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    console.error(error)
    if (error.code == 'ERR_CANCELED') {
    } else if (error.response?.status == 500) {
      console.error('Oops! Something went wrong.')
    } else if (error.response?.status == 400) {
      console.error('Something went wrong with the request.')
    } else if (error.response?.status == 401) {
      console.error('Unauthorized')
    } else if (error.response?.status == 403) {
      console.error('Credential error.')
    } else if (error.response?.status == 404) {
      console.error('Page not found.')
    }

    return Promise.reject(error)
  }
)

export { http as axios }
export { http as default }
