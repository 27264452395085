const SideActivity = (props) => {
	return (
		<button
			className="flex items-center p-4 hover:bg-gray-700 h-[60px] cursor-pointer w-full"
			onClick={() => props.onClick(props.data)}
		>
			<div className="ml-4 flex flex-col items-left items-start ">
				<div className="text-sm font-medium text-white">
					{props.data.label}
				</div>{' '}
			</div>
		</button>
	)
}

export default SideActivity
