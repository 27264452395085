import React from 'react';

const MenuIcon = ({ fill = "#768C9E", className = "w-7 h-7" }) => {
  return (
    <svg viewBox="0 0 24 24"  fill={fill} className={className}  xmlns="http://www.w3.org/2000/svg">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path d="M4 18L20 18" stroke={fill} strokeWidth="2" strokeLinecap="round"></path>
        <path d="M4 12L20 12" stroke={fill} strokeWidth="2" strokeLinecap="round"></path>
        <path d="M4 6L20 6" stroke={fill} strokeWidth="2" strokeLinecap="round"></path>
      </g>
    </svg>
  );
}

export default MenuIcon;
