import React, { useState, useEffect } from "react";
import * as aapi from '@api/admin'

const Parents = () => {
    const [data, setData] = useState([]);
    const [newParentName, setNewParentName] = useState("");

    useEffect(() => {
        aapi.parents()
            .then(res => setData(res.data.data))
            .catch(e => console.error(e.response));
    }, []);

    const handleCreateParent = (event) => {
        event.preventDefault();  // Prevent the default form submit action
        aapi.createParent({ username: newParentName })
            .then(() => {
                setNewParentName(""); // Reset input after submission
                // Optionally refresh the list or handle the new data
                console.log("Parent created successfully");
            })
            .catch(e => alert("Failed to create parent:", e));
    };

    return (
        <div className="container mx-auto px-4">
            <form onSubmit={handleCreateParent} className="mb-4">
                <input
                    type="text"
                    placeholder="Enter parent name"
                    value={newParentName}
                    onChange={e => setNewParentName(e.target.value)}
                    className="px-2 py-1 border rounded"
                />
                <button type="submit" className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded">
                    Create Parent
                </button>
            </form>
            <table className="table-auto w-full text-left whitespace-no-wrap">
                <thead>
                    <tr className="text-xs font-semibold tracking-wide text-gray-500 uppercase border-b bg-gray-50">
                        <th className="px-4 py-3">ID</th>
                        <th className="px-4 py-3">Username</th>
                        <th className="px-4 py-3">Name</th>
                        <th className="px-4 py-3">Status</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y">
                    {data.map(parent => (
                        <tr key={parent.id} className="text-gray-700">
                            <td className="px-4 py-3 text-sm">{parent.id}</td>
                            <td className="px-4 py-3 text-sm">{parent.username}</td>
                            <td className="px-4 py-3 text-sm">{parent.first_name} {parent.last_name}</td>
                            <td className="px-4 py-3 text-sm">{parent.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Parents;
