// src/components/SEOHelmet.js
import React from 'react';
import { Helmet } from "react-helmet";

const SEOHelmet = ({
  title = "Alfagram",
  description = "The most useful crypto tool for alpha hunters.",
  // image = `${process.env.PUBLIC_URL}/ag.jpg`,  // Set default image path
  url = "https://alfagram.io",
}) => {
  return (
    <Helmet>
      {/* Basic meta tags */}
      <title>{title}</title>
      <meta name="keywords" content={`${title}, ${description}, crypto `} />
      <meta name="description" content={description} />

      <meta property="title" content={title} />
      <meta property="description" content={description} />
      {/* <meta property="image" content={image} /> */}
      <meta property="url" content={url} />
      {/* <meta property="image:width" content="100" />
      <meta property="image:height" content="100" /> */}
      
      {/* OpenGraph tags for Facebook and LinkedIn */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* <meta property="og:image" content={image} /> */}
      <meta property="og:url" content={url} />
      {/* <meta property="og:image:width" content="100" />
      <meta property="og:image:height" content="100" /> */}

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* <meta name="twitter:image" content={image} /> */}
      {/* <meta property="twitter:image:width" content="100" />
      <meta property="twitter:image:height" content="100" /> */}
    </Helmet>
  );
};

export default SEOHelmet;
