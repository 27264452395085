import React, {
	useState,
	useEffect,
	useRef,
	useCallback,
	useContext
} from 'react'
import telegramStore from '@store/telegram'
import * as tapi from '@api/telegram'
// import * as capi from '@api/child'
import { Tabs } from '../consts'
import useAuth from '@hooks/useAuth'
import * as helpers from '@api/helpers'
import telegram from '../../../stores/telegram'
import { Exception } from 'sass'

export const useTelegramHook = () => {

	const auth = useAuth()
	const [activeTab, setActiveTab] = useState(Tabs[0])
	const [activeChat, setActiveChat] = useState(null)
	const [data, setData] = useState({
		groups: [],
		parents: [],
		watchlist: { parents: [], groups: [] }
	})

	// console.log('auth', auth.user)
	// console.log(activeChat, activeTab)


	// HANDLE NEW GROUP MESSAGES SOCKET
	useEffect(() => {

		const addMsgs = (msg, storedMessages=[]) => {
			const newMsgs = {
				...storedMessages, 
				messages: helpers.appendNewData([msg], storedMessages.messages)
			}
			newMsgs.messages.sort((a, b) => b.id - a.id);
			telegramStore.setChatMessages(msg.group_id, newMsgs)
		}

		auth.socket.io.on('o_telegram_new_messages',  async (response) => {
			// console.log(response?.data.messages)
			try {
				// console.log(telegramStore.groups)
				for (const msg of response.data.messages) {
					// if (msg.user.channel) {
					// 	console.log(msg)
					// }
					// console.log(msg.group_id)
					let group = telegramStore.getGroup(msg.group_id)
					let storedMessages = telegramStore.getChatMessages(msg.group_id)
					// return
					if (!group) {
						// console.warn('group is not in store')
						const response = await tapi.groups({ groupId: msg.group_id })
						group = response.data.data?.length ? response.data.data [0] : null
						if (!group) {
							console.error('group not found')
						} 
						// console.log(group)
						telegramStore.addGroup(group, true)
					}  else {
						addMsgs(msg, storedMessages)
					}

				}
			} catch (e) {
				console.error(e)
			}
		})


		auth.socket.io.on('o_telegram_new_groups',  async (response) => {
			try {
				// console.log(telegramStore.groups)
				if (!response?.data) return
				for (const group of response?.data) {
					// console.log(group)
					telegramStore.addGroup(group)

					// let group = telegramStore.getGroup(msg.group_id)
					// addMsgs(msg, storedMessages)
				}
			} catch (e) {
				console.error(e)
			}
		})


		auth.socket.io.on('o_children_watchlist_update', (data) => {
			try {
				console.log('watchlist response')
				console.log(data)
				telegramStore.setGroupWatchlist(data)
			} catch(e) {
				console.error(e)
			}
		})

		return () => {
		  auth.socket.io.off('o_telegram_new_groups')
		  auth.socket.io.off('o_telegram_new_messages')
		  auth.socket.io.off('o_children_watchlist_update')
		}
	}, [])


	// HANDLE ACTIVE CHAT
	useEffect(() => {
		// console.log(activeChat)
		const twitterId = telegramStore.activeChat?.twitter_id
		// console.log('twitter_id', twitterId)
		if (twitterId) {

			tapi.child({ twitterId: telegramStore.activeChat.twitter_id })
				.then(res => telegramStore.setActiveChild(res.data.data.child))
				.catch(e => console.error(e?.response?.data))
		} else {
			telegramStore.setActiveChild(null)
		}

		// telegramStore.setActiveChat(activeChat)
		setActiveChat(telegramStore.activeChat)
	}, [telegramStore.activeChat])


	// HANDLE ACTIVE TAB
	useEffect(() => {
		// console.log(' out , ', activeTab, telegramStore.getActiveTab()?.key)

		setActiveTab(telegramStore.activeTab)
	}, [telegramStore.activeTab])


	// HANDLE GROUP FETCH
	useEffect(() => {
		const fetchGroups = async () => {
			try {
				const res = await tapi.groups(telegramStore.groupsQuery);
				setData(currentData => ({ ...currentData, groups: res.data.data }));
				telegramStore.setGroups(res.data.data); // Update if necessary
			} catch (e) {
				console.error(e);
			}
		};
		fetchGroups();

	}, [telegramStore.groupsQuery]);


	// HANDLE PARENTS FETCH
	useEffect(() => {
		const fetchParents = async () => {
			try {
				const res = await tapi.parents(telegramStore.groupsQuery);
				setData(currentData => ({ ...currentData, parents: [{ id: 'All' }, ...res.data.data] }));
				telegramStore.setParents(res.data.data); // Update if necessary
			} catch (e) {
				console.error(e);
			}
		};
		fetchParents();

	}, [telegramStore.groupsQuery.searchParent]);


	// HANDLE WATCHLIST FETCH
	useEffect(() => {

		const fetchWatchlist = async () => {
			try {
				const res = await tapi.watchlist({});
				setData(currentData => ({ ...currentData, watchlist: res.data.data }));
				telegramStore.setWatchlist(res.data.data); // Update if necessary
			} catch (e) {
				console.error(e);
			}
		};

		if(auth.user) fetchWatchlist();
	}, [auth?.user]);


	// console.log('len groups: ', telegramStore.groups.length)
	return { data, setActiveChat, setActiveTab }
}

export default useTelegramHook