import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as tapi from '@api/telegram'
import { observer } from 'mobx-react-lite'
import telegramStore from '@store/telegram'
import { Tabs } from '../../consts'
import MessageRow from '../../components/MessageRow'
import * as helpers from '@api/helpers'
import MessageWrapper from '../MessageWrapper'
import Spinner from '@components/Spinner'
import * as _ from 'lodash'
import useMessages from '../../../../hooks/useMessages'
import ScrollWrapper from '../../components/ScrollWrapper'
import useAuth from '../../../../hooks/useAuth'
import { toJS } from 'mobx';

const Messages = observer(() => {
	const auth = useAuth()
	const getQuery = () => {
		return { page: 1 }
	}

	const {
		data,
		triggerAutoScrollBottom,
		triggerAutoScrollTop,
		lastChildRef,
		chatLoading,
		setData
	} = useMessages('activity', getQuery, 'activityMessagesQuery')

	
	const messages = data.messages?.slice()?.reverse() ?? []

	if (chatLoading) return <Spinner />
	console.log(triggerAutoScrollTop)

	return (
		<div
			id="tg-chat-area"
			className="flex w-full flex-col main-bg-color relative  h-[98%] overflow-auto"
		>
			<ScrollWrapper
				data={messages}
				query={telegramStore.activityMessagesQuery}
				triggerAutoScrollTop={triggerAutoScrollTop}
				triggerAutoScrollBottom={triggerAutoScrollBottom}
				chatLoading={chatLoading}
			>
				<MessageWrapper data={messages} lastChildRef={lastChildRef} />
			</ScrollWrapper>
		</div>
	)
})

export default Messages
