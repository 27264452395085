import React from 'react';
import Clients from './Clients';
import Groups from './Groups'; // Uncomment when Users component is ready
import Parents from './Parents'; // Uncomment when Users component is ready
import Sidebar from './Sidebar';
import { Routes, Route } from 'react-router-dom';

const Admin = () => {
    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-1">
                <Routes>
                    <Route path="clients" element={<Clients />} />
                    <Route path="parents" element={<Parents />} />
                    <Route path="groups" element={<Groups />} />
                </Routes>
            </div>
        </div>
    );
}

export default Admin;
