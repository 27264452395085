import React, { useState, useEffect } from 'react'
import * as aapi from '@api/admin'
import * as helpers from '@api/helpers'

const Groups = () => {
	// Example data for the table

	const [data, setData] = useState([])
    const [searchClient, setSearchClient] = useState("")

	useEffect(() => {
		aapi.groups({ client_id: searchClient })
			.then((res) => setData(res.data.data))
			.catch((e) => console.error(e.response))
	}, [searchClient])


	return (
		<div className="container mx-auto px-4">
            <input
                type="text"
                placeholder="Enter client name"
                value={searchClient}
                onChange={e => setSearchClient(e.target.value)}
                className="px-2 py-1 border rounded"
            />
            <button type="submit" className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded">
                Search by client
            </button>
			<table className="table-auto w-full text-left whitespace-no-wrap">
				<thead>
					<tr className="text-xs font-semibold tracking-wide text-gray-500 uppercase border-b bg-gray-50">
						<th className="px-4 py-3">ID</th>
						{/* <th className="px-4 py-3">Name</th> */}
						<th className="px-4 py-3">Username</th>
						<th className="px-4 py-3">Joined at</th>
						<th className="px-4 py-3">Last Updated</th>
						<th className="px-4 py-3">Last Message</th>
						<th className="px-4 py-3">Link</th>
						<th className="px-4 py-3">Passed Captcha</th>
						<th className="px-4 py-3">Client ID</th>
						<th className="px-4 py-3">Twitter ID</th>
						<th className="px-4 py-3">Error</th>
					</tr>
				</thead>
				<tbody className="bg-white divide-y">
					{data.map((group) => (
						<tr key={group.id} className="text-gray-700">
							<td className="px-4 py-3 text-sm">{group.id}</td>
							{/* <td className="px-4 py-3 text-sm">{group.title}</td> */}
							<td className="px-4 py-3 text-sm">
								{group.username}
							</td>
							<td className="px-4 py-3 text-sm">
								{group.joined_at}
							</td>
							<td className="px-4 py-3 text-sm">
								{helpers.timeAgo(group.last_update)}
							</td>
							<td className="px-4 py-3 text-sm">
								{group.last_message_timestamp ? helpers.timeAgo(group.last_message_timestamp) : ''}
							</td>
							<td className="px-4 py-3 text-sm">
								<a
									href={group.link_to_join}
									target="_blank"
									rel="noopener noreferrer"
								>
									{group.link_to_join}
								</a>
							</td>
							<td className="px-4 py-3 text-sm">
								{group.passed_captcha?.toString()}
							</td>
                            <td className="px-4 py-3 text-sm">
								{group.client_id}
							</td>
							<td className="px-4 py-3 text-sm">
								{group.twitter_id}
							</td>
                            <td className="px-4 py-3 text-sm">
								{group.error}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

export default Groups
