import React from 'react'

const SideOption = (props) => {
  return (
    <button className={` min-h-[80px] flex flex-col items-center gap-1 hover:bg-gray-700 w-full py-2 px-3 ${props.isActive ? 'bg-gray-700' : ''}`} onClick={() => props.onClick(props.option)} >
      <div className='  rounded-[50px] border-[#768C9E]'>

        <div className='p-1'>
          {React.cloneElement(props.option.icon,
            {
              ...props.isActive && { fill: '#5eb5f7' }
            }
          )}
        </div>

      </div>
      <div className={`text-sm  Squad-font ${props.isActive ? 'text-[#4c94d4]' : 'text-[#5e6e83]'} `}>{props.option.label}</div>
    </button>
  )
}

export default SideOption
