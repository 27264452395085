import React, { useState, useEffect } from 'react';
import useMobile from '@hooks/useMobile';
import Overlay from '@components/Overlay';
import { motion } from 'framer-motion';
import { DotsIcon } from '../assets';

const BaseModal = React.memo((props) => {
    const { isSmScreen } = useMobile();
    const [showOverlay, setShowOverlay] = useState(false);

    const onOverlayClose = () => {
        setShowOverlay(false);
    };

    // Effect to handle the Enter key press
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                onOverlayClose();
            }
        };

        // Only attach the event listener when the overlay is shown
        if (showOverlay) {
            document.addEventListener('keydown', handleKeyDown);
        }

        // Cleanup function to remove the event listener
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [showOverlay]);  // Depend on showOverlay to add/remove the event listener

    return (
        <div id="base-modal" className="">
            <button
                onClick={() => {
                    setShowOverlay((prev) => !prev);
                }}
            >
                <DotsIcon />
            </button>
            <div className={`${!showOverlay ? 'hidden' : ''}`}>
                <Overlay
                    isOverlayOpen={true}
                    setIsOverlayClose={onOverlayClose}
                >
                    <div
                        id="dropdownNotification"
                        className="h-full flex flex-col overflow-hidden divide-y divide-gray-100 rounded-lg shadow dark:divide-gray-700 filters-modal "
                        aria-labelledby="dropdownNotificationButton"
                        onClick={(e) =>
                            e.target.classList.value.includes(
                                'filters-modal'
                            ) && onOverlayClose()
                        }
                    >
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            className="h-full max-h-[600px] p-2 ml-2 w-full m-auto z-[50] rounded-[4px] flex flex-col items-center filters-modal space-y-4"
                        >
                            {/* Render the component passed in props */}
                            <props.element {...props} />
                        </motion.div>
                    </div>
                </Overlay>
            </div>
        </div>
    );
});

export default BaseModal;
