import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as tapi from '@api/telegram'
import * as helpers from '@api/helpers'
import telegramStore from '@store/telegram'
import * as _ from 'lodash'
import { debounce } from 'lodash';
import { toJS } from 'mobx'

const INIT_MSGS = { messages: [], hasNext: undefined }

const useMessages = (chatId, getQuery, queryKey) => {
	// if (!chatId) return
    const [triggerAutoScrollTop, setTriggerAutoScrollTop] = useState(true)
	const [triggerAutoScrollBottom, setTriggerAutoScrollBottom] = useState(true)
	const [chatLoading, setChatLoading] = useState(true)
	const [isFetching, setIsFetching] = useState(false) // New state to prevent multiple fetches
	const [init, setInit] = useState(true)
	const [triggerFetchMessages, setTriggerFetchMessages] = useState(true)

	const [data, setData] = useState(
		telegramStore.getChatMessages(chatId) || INIT_MSGS
	)

	getQuery = () => {
		const query = toJS(telegramStore.getMessagesQuery(chatId)) || { page: 1, groupId: telegramStore.activeChat?.id }
		// console.log('query', query)
		return query
	}

	useEffect(() => {
		try {
			if (!chatId) return
			telegramStore.setQuery(queryKey, chatId, getQuery())

			if (telegramStore.getChatMessages(chatId)?.messages?.length > 100) {
				// console.warn(telegramStore.getChatMessages(chatId).messages.length)
				setData(telegramStore.getChatMessages(chatId))
				setTriggerAutoScrollBottom(true)	
				return
			}
			setChatLoading(true)
			setTriggerAutoScrollBottom(true)
			setTriggerFetchMessages(prev => !prev)
			telegramStore.setChatMessages(INIT_MSGS)
		} catch(e) {
			console.log(e)
		} finally {
			setInit(false)
			
		}

	}, [chatId])

	useEffect(() => {
		setTriggerFetchMessages(prev => !prev)
		console.log('')
	}, [telegramStore.getMessagesQuery(chatId)?.text])

	useEffect(() => {
		if (!chatId) {
			if (chatLoading) setChatLoading(false)
			return
		}
		
		const query = getQuery()
		// console.log(toJS(query), toJS(telegramStore.getChatMessages(chatId)?.messages?.length))

		// if (init || (toJS(telegramStore.getChatMessages(chatId)?.messages?.length)) > 100) {
		let msgs = toJS(telegramStore.getChatMessages(chatId))
		// if (init || (query.page == 1 && msgs?.messages?.length <= 100 && msgs?.fetched)) {
		// 	setChatLoading(false)
		// 	return
		// }

		// if (init) {
		// 	setChatLoading(false)
		// 	return
		// }

		if (!query) return
		setIsFetching(true)
		
		tapi.messages(query)
			.then((res) => {
				const prevMessages = _.cloneDeep(data.messages)
				const newMessages =
					query.page > 1
						? helpers.appendNewData(
								prevMessages,
								res.data.data.messages
						  )
						: res.data.data.messages

				let newData = {
					messages: newMessages,
					hasNext: res.data.data.hasNext,
					fetched: true
				}

				// newData.messages.sort((a, b) => b.id - a.id);

				telegramStore.setChatMessages(
					chatId,
					newData
				)
				setData(newData)

				// Auto scroll
				if (query.page > 1) {
					setTriggerAutoScrollTop((prev) => !prev)
				} else {
					setTriggerAutoScrollBottom((prev) => !prev)
				}

			})
			.catch((e) => console.error(e))
			.finally(() => {
				setIsFetching(false)
				setChatLoading(false)
			})
	}, [triggerFetchMessages])

	// console.log(toJS(telegramStore.getMessagesQuery(chatId)))
	/**
	 * Intersection observer for infinite scrolling
	 *
	 * */
	const intObserver = useRef()
	const handleIntersection = useCallback(
		debounce((entries) => {
			if (entries[0].isIntersecting && data.hasNext && !isFetching) {
				const prevQuery = telegramStore.getMessagesQuery(chatId)
				telegramStore.setQuery(queryKey, chatId, {
					...prevQuery,
					page: prevQuery.page + 1
				})
				setTriggerFetchMessages(prev => !prev)
			}
		}, 500),
		[data.hasNext, isFetching]
	)

	const lastChildRef = useCallback(
		(node) => {
			if (!node || chatLoading || isFetching) return
			if (intObserver.current) intObserver.current.disconnect()
			intObserver.current = new IntersectionObserver(handleIntersection)
			intObserver.current.observe(node)
		},
		[chatLoading, isFetching, handleIntersection]
	)

    return { data, triggerAutoScrollBottom, triggerAutoScrollTop, lastChildRef, chatLoading, setData } 
}

export default useMessages
