import axios from './axios'

const VERIFICATION_MESSAGE_URL = process.env.REACT_APP_API + '/verify'
const LOGIN_URL = process.env.REACT_APP_API + '/login'

export async function getMessage(data) {
  return await axios.get(VERIFICATION_MESSAGE_URL, {
    params: data,
    method: "Get",
    headers: {
      "Content-Type": "application-json",
    },
  });
}




export async function login(data) {
  return await axios.post(LOGIN_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application-json",
    },
    data
  });
}

