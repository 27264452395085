import React, { useEffect, useState } from 'react'
import {
	AdminIcon,
	LinksIcon,
	MembersIcon,
	FollowersIcon,
	KeyFollowersIcon
} from '../../assets'
import { observer } from 'mobx-react-lite'
import telegramStore from '@store/telegram'
import Spinner from '@components/Spinner'
// import Socials from '.components/Socials'
import { useInView } from 'react-intersection-observer'
import Socials from '../../assets/Icons/Socials'
import * as tapi from '@api/telegram'
import * as helpers from '@api/helpers'
import UserImage from '@tg/components/UserImage'
import ProfileModal from './ProfileModal'
import MemberModal from '@tg/components/MemberModal'
import MemberList from './MemberList'
import { toJS } from 'mobx'

const Divider = () => {
	return (
		// Adjust the gradient colors and shadow to match your design
		<div className="w-full h-[9px] bg-[#232e3c] flex-shrink-0"></div>
	)
}

const Metric = (props) => {
	return (
		<div className="flex flex-row items-center opacity-90 text-[15px] font-sans gap-3">
			<props.icon />
			{/* 1 saved message <br/>
			231 videos <br/>
			77 files */}
			{props.value} {props.text}
		</div>
	)
}

const JoinsList = ({ group }) => {
	const [data, setData] = useState(null)

	useEffect(() => {
		tapi.joins({ groupId: group.id })
			.then((res) => setData(res.data.data))
			.catch((e) => console.error(e?.response))
	}, [group?.id])

	if (!data?.joins?.length) return

	return (
		<div className="flex flex-col gap-1">
			<div className="flex flex-col items-center">
				<div className="font-bold ">{'Joins'}</div>
			</div>

			<div className="flex flex-col gap-1">
				{data?.joins?.length
					? data.joins.map((join) => (
							<div className="flex flex-row gap-6 items-center">
								<UserImage user={join.parent} />
								{helpers.timeAgo(join.timestamp)} ago
							</div>
					  ))
					: null}
			</div>
		</div>
	)
}

const GroupDetails = observer(() => {
	// console.log('members ', members)
	// console.log('details:', group)
	const child = telegramStore.activeChild
	const group = telegramStore.activeChat
	const members = telegramStore.activeDetails

	// console.log(toJS(group))
	return (
		<div className="inner-bg-color  h-full font-sans  shadow-md  flex flex-col gap-4  ">
			{/* Sidebar content goes here */}
			<div className=" px-5  flex items-center text-white min-h-[50px] w-[320px] text-sm font-bold ">
				Group Info
			</div>

			<div className="overflow-auto flex flex-col gap-4">
				<div className=" px-5  flex flex-col gap-4 ">
					{/* Image, title, total members */}
					<div className=" flex flex-row gap-4 items-center pt-4">
						<a
							href={group.link_to_join}
							target="_blank"
							rel="noopener noreferrer"
						>
							<div className="relative h-[70px] w-[70px] ">
								<img
									className="h-[70px] w-[70px] rounded-full "
									alt={group.title} // Added alt attribute for accessibility
									src={`${process.env.REACT_APP_IMAGES_SERVER}/imgs/groups/${group.id}.jpg`}
								/>
								<span
									className={`absolute bottom-0 right-0 h-4 w-4 rounded-full ${
										group.active
											? 'bg-green-500'
											: 'bg-red-500'
									}`}
								></span>
							</div>
						</a>
						<div className="flex flex-col  gap-2">
							<div className="font-bold text-[14px]">
								{group.title}
							</div>
							<div className="text-xs opacity-50">
								{group.total_members} members
							</div>
						</div>
					</div>
				</div>

				{/* Project Info */}
				{child ? (
					<>
						<Divider />
						<div className=" px-5  flex flex-col gap-4 ">
							<div className="flex flex-col gap-3">
								<Socials data={child} />
								<Metric
									icon={KeyFollowersIcon}
									text="Key Profiles"
									value={child.key_followers_count}
								/>
								<Metric
									icon={FollowersIcon}
									text="Followers"
									value={child.followers_count}
								/>
								<Metric
									icon={FollowersIcon}
									text="Member 1D"
									value={child.followers_count}
								/>
							</div>{' '}
						</div>
					</>
				) : child === undefined ? (
					<Spinner />
				) : (
					<div>live contract proj</div>
				)}

				{/* Group Members> */}

				<div className=" px-5  flex flex-col gap-4  ">
					<JoinsList group={group} />
				</div>
				<Divider />
				<div className=" px-5  flex flex-col gap-4  ">
					<MemberList
						title="Members"
						total_members={group.total_members}
					/>
				</div>
			</div>
		</div>
	)
})

export default GroupDetails
