import React, { useContext, useEffect, useMemo, useState } from 'react'
import SideOption from './SideOption'
import { BurgerIcon } from '../TelegramPlayground/assets'
import { Tabs } from '../TelegramPlayground/consts'
import { observer } from 'mobx-react-lite'
import telegramStore from '@store/telegram'

const OuterSidebar = observer((props) => {
  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <div className="flex flex-col main-bg-color text-left ">
      <div className='flex flex-col items-center  w-full'>

        <button className="items-center flex flex-row h-[52px]" onClick={() => setIsExpanded(prev => !prev)}>
          <BurgerIcon />

        </button>
      </div>
      {Tabs.map((tab, i) => {
        return (
          <SideOption
            key={i}
            option={tab}
            onClick={() => props.onOuterOptionClick(tab)}
            isActive={telegramStore.activeTab.label == tab.label}
          />
        )
      })}
    </div>
  )
})

export default OuterSidebar
