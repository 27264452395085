import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

const Layout = () => {
	return (
		<div id="main-layout" className="w-full h-screen p-3 bg-[#03020e]">
			<Outlet />
		</div>
	)
}

export default Layout
