import React, { useState, useEffect } from "react";
import * as aapi from '@api/admin'

const Clients = () => {
    // Example data for the table

    const [data, setData] = useState([])

    useEffect(() => {
        aapi.clients()
        .then(res => setData(res.data.data))
        .catch(e => console.error(e.response))
    }, [])


    console.log(data)
    return (
        <div className="container mx-auto px-4">
            <table className="table-auto w-full text-left whitespace-no-wrap">
                <thead>
                    <tr className="text-xs font-semibold tracking-wide text-gray-500 uppercase border-b bg-gray-50">
                        <th className="px-4 py-3">ID</th>
                        <th className="px-4 py-3">Name</th>
                        {/* <th className="px-4 py-3">Session</th> */}
                        <th className="px-4 py-3">Username</th>
                        <th className="px-4 py-3">Total Groups</th>
                        <th className="px-4 py-3">Status</th>
                        <th className="px-4 py-3">Error</th>
                        <th className="px-4 py-3">Rotated</th>
                        <th className="px-4 py-3">Rotated from</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y">
                    {data.map((client) => (
                        <tr key={client.id} className="text-gray-700">
                            <td className="px-4 py-3 text-sm">{client.id}</td>
                            <td className="px-4 py-3 text-sm">{client.first_name} {client.last_name}</td>
                            {/* <td className="px-4 py-3 text-sm">{client.session_file?.replace('.session', '')}</td> */}
                            <td className="px-4 py-3 text-sm">{client.username}</td>
                            <td className="px-4 py-3 text-sm">{client.total_groups}</td>
                            <td className="px-4 py-3 text-sm">{client.status}</td>
                            <td className="px-4 py-3 text-sm">{client.error}</td>
                            <td className="px-4 py-3 text-sm">{client.rotated?.toString()}</td>
                            <td className="px-4 py-3 text-sm">{client.rotated_from}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Clients;
