import { action, makeAutoObservable, makeObservable, observable } from 'mobx'
import { Tabs } from '@tg/consts'

class Telegram {

	constructor() {
		this.groups = []
		this.parents = []
		this.watchlist = { parents: [], groups: [] }
		// this.data = {
		// 	groups: [],
		// 	parents: [],
		// 	watchlist: { parents: [], groups: [] }
		// }

		this.messages = new Map()

		this.activeChat = null
		this.activeTab = Tabs[0]
		this.activeChild = null

		this.messagesQuery = new Map()
		this.activityMessagesQuery = {
			groupId: null,
			page: 1,
			text: null,
			admin: null
		}
		this.groupsQuery = {}
		this.tabType = 'group'
		this.activeDetails = null

		// Joins
		this.joins = new Map()
		this.joinsQuery = {
			id: null,
			page: 1
		}
		this.historyStore = null
		makeAutoObservable(this)
	}

	groupIsWatchlisted = (id) => {
		return !!this.watchlist.groups.find(group => group.telegram_id == id)
	}

	setHistoryStore = (st) => {
		this.historyStore = st
	}

	getLastMessageOfChat(chat_id) {
		const msgs = this.getChatMessages(chat_id)?.messages
		// console.log(msgs)
		if (!msgs?.length) return null
		return msgs[0]
	}

	getChatMessages(chat_id) {
		return this.messages.get(chat_id)
	}

	getJoins(chat_id) {
		return this.joins.get(chat_id)
	}

	getActiveJoins() {
		return this.joins.get(this.activeChat.id)
	}

	getActiveChatMessages() {
		return this.messages.get(this.activeChat.id)
	}

	setJoins = action((chat_id, joins) => {
		this.joins.set(chat_id, joins)
	})

	setChatMessages = action((chat_id, messages) => {
		this.messages.set(chat_id, messages)
	})

	setActiveChild(data) {
		this.activeChild = data
	}

	getActiveChild(data) {
		return this.activeChild
	}


	setJoinsQuery(query) {
		this.joinsQuery = query
	}

	getJoinsQuery() {
		return this.joinsQuery
	}

	setMessagesQuery = action((id, query) => {
		this.messagesQuery.set(id, query)
	})

	getMessagesQuery(id) {
		return this.messagesQuery.get(id)
	}

	setGroupsQuery(query) {
		this.groupsQuery = query
	}

	getGroupsQuery() {
		return this.groupsQuery
	}

	getActiveTab() {
		return this.activeTab
	}

	getTabType() {
		return this.tabType
	}

	setQuery = action((queryKey, id, query) => {
		if (!id) return
		this[queryKey].set(id, query)
	})

	setActiveTab(tab) {
		this.activeTab = tab
		this.tabType = ['profiles', 'myprofiles'].includes(tab.key) ? 'profile' : ['chat', 'mygroups'].includes(tab.key) ? 'group' : 'activity'
	}

	isProfileTab() {
		return ['profiles', 'myprofiles'].includes(this.getActiveTab())
	}

	getActiveChat() {
		return this.activeChat
	}

	setActiveChat(chat) {
		this.activeChat = chat
	}

	setGroups(data) {
		this.groups = data
	}

	setParents(data) {
		this.parents = data
	}

	setGroupWatchlist(data) {
		this.watchlist = { ...this.watchlist, groups: data }
	}

	setWatchlist(data) {
		this.watchlist = data
	}

	setActiveDetails(data) {
		this.activeDetails = data
	}

	getActiveDetails() {
		return this.activeDetails
	}

	getGroups() {
		return this.groups
	}

	getParents() {
		return this.parents
	}

	getGroup(id) {
		return this.groups.find(group => group.id == id)
	}


	addGroup = action((group, end = false) => {
		// Prevent adding the group if it already exists
		if (this.getGroup(group.id)) return;
		// Add new group at the beginning of the array
		if (end)
			this.groups.push(group);
		else
			this.groups.unshift(group);
	})

	onChatClick = (chat, tabType = null) => {
		if (this.getActiveChat()?.id == chat?.id) return
		// console.log(chat, tabType)
		this.historyStore.push({
			tab: this.getActiveTab(),
			chat: this.getActiveChat(),
			details: this.getActiveDetails(),
			child: this.getActiveChild()
		})
		if (tabType) this.setActiveTab(Tabs.find((tab) => tab.key === tabType))
		this.setActiveDetails(null)
		this.setActiveChat(chat)
		this.setActiveChild(undefined)
		// setActiveChat(chat)
	}

	onGroupClick = (group) => {
		console.log(this.historyStore.history)
		this.setActiveTab(Tabs.find((tab) => tab.key === 'chat'))
		this.setActiveChat(group)
	}


}

export default new Telegram()