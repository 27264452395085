import React from 'react'
import { observer } from 'mobx-react-lite'
import telegramStore from '@store/telegram'
import { Tabs } from '../consts'

const Group = observer(({ group, className = '' }) => {
	if (!group) return null
	return (
		<button
			onClick={() => telegramStore.onChatClick(group, 'chat')}
			className="text-green-400 flex flex-row gap-2 items-center"
		>
			<a
				href={group.link_to_join}
				target="_blank"
				rel="noopener noreferrer"
			>
				<div className="relative h-8 w-8">
					<img
						className="h-8 w-8 rounded-full"
						alt={group.title} // Added alt attribute for accessibility
						src={`${process.env.REACT_APP_IMAGES_SERVER}/imgs/groups/${group.id}.jpg`}
					/>
					<span
						className={`absolute bottom-0 right-0 h-2 w-2 rounded-full ${
							group.active ? 'bg-green-500' : 'bg-red-500'
						}`}
					></span>
				</div>
			</a>
			<div className={className}>{group?.title?.substring(0, 20)}</div>
		</button>
	)
})

export default Group
