import axios from './axios'

const CLIENTS_URL = process.env.REACT_APP_API + '/admin/clients'
const GROUPS_URL = process.env.REACT_APP_API + '/admin/groups'
const PARENTS_URL = process.env.REACT_APP_API + '/admin/parents'
const CREATE_PARENT_URL = process.env.REACT_APP_API + '/admin/parents/create'


export async function createParent(data) {
    return await axios.post(CREATE_PARENT_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application-json",
      },
      data
    });
  }
  

export async function parents(query) {

    let config = {
        params: query,
        headers: {
            'Content-Type': 'application-json'
        }
    }
    return await axios.get(PARENTS_URL, config).then((res) => {
        return res
    })

}


export async function groups(query) {

    let config = {
        params: query,
        headers: {
            'Content-Type': 'application-json'
        }
    }
    return await axios.get(GROUPS_URL, config).then((res) => {
        return res
    })

}


export async function clients(query) {

    let config = {
        params: query,
        headers: {
            'Content-Type': 'application-json'
        }
    }
    return await axios.get(CLIENTS_URL, config).then((res) => {
        return res
    })

}
