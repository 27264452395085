function SearchBar({ text, onChange }) {
    return (
      <div className="flex items-center justify-between px-4 py-2">
        <input
          type="text"
          placeholder="Search"
          value={text || ''}
          onChange={onChange}
          className="flex-grow p-2 bg-[#242f3d] text-white placeholder-gray-400 max-w-[235px] Squad-font placeholder:text-sm placeholder:pl-2   rounded-[50px] text-sm border-none"
        />
      </div>
    );
  }
  
  export default SearchBar;
  