import React, { useContext, createContext, useEffect, useState } from 'react'
import axios from '../api/axios'

const authContext = createContext()

export function ProvideAuth(props) {
  const auth = useProvideAuth(props)

  return (
    <authContext.Provider value={auth}>{props.children}</authContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(authContext)
}

export async function fetchSession() {
  return await axios.get('/api/profile')
} 

async function getInitialState() {
  const user = localStorage.getItem('user')
  return user ? JSON.parse(user) : null
}

export function useProvideAuth({ state }) {
  const [user, setUser] = useState(state.user);
  const [socket, setSocket] = useState(state.socket);

  // React to changes in the incoming state
  useEffect(() => {
    setUser(state.user);
    setSocket(state.socket);
  }, [state.user, state.socket]); // Depend on state.user and state.socket

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
    // localStorage.setItem('socket', JSON.stringify(socket));
  }, [user, socket]);

  return { socket, setSocket, user, setUser };
}



export default useAuth