import React, { useEffect, useState } from 'react'
import { AdminIcon, MembersIcon } from '../../assets'
import { observer } from 'mobx-react-lite'
import telegramStore from '@store/telegram'
import ProfileModal from './ProfileModal'
import MemberModal from '@tg/components/MemberModal'
import { toJS } from 'mobx'
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll'
import * as tapi from '@api/telegram'
import Spinner from '@components/Spinner'

const MemberList = observer(({ title, total_members }) => {
	// if (!members) return null
	const [showOverlay, setShowOverlay] = useState(false)

	const apiCallOptions = {
		apiCall: tapi.members,
		getQuery: () => {
			return { groupId: telegramStore.activeChat?.id }
		},
		resultKey: 'members',
		queryKey: 'groupId'
	}

	const { isLoading, data, lastChildRef, page } =
		useInfiniteScroll(apiCallOptions)


	// useEffect(() => {
	// 	if (!telegramStore.activeChat?.id) {
	// 		telegramStore.setActiveDetails(null)
	// 		return
	// 	}
	// 	// TODO , UPDATE MEMBERS ON SCROLL
	// 	if (['chat', 'mygroups'].includes(telegramStore.activeTab?.key)) {
	// 		// tapi.members({ groupId: telegramStore.activeChat.id })
	// 		// 	.then((res) => telegramStore.setActiveDetails(res.data.data))
	// 		// 	.catch((e) => console.error(e))
	// 	}
	// }, [telegramStore.activeChat])

	const Divider = () => {
		return (
			// Adjust the gradient colors and shadow to match your design
			<div className="w-full h-[9px] bg-[#232e3c] flex-shrink-0"></div>
		)
	}

	const Member = ({ member, lastChildRef }) => {
		const [showOverlay, setShowOverlay] = useState(false)

		return (
			<div
				className="flex flex-row items-center gap-4 w-full "
				ref={lastChildRef}
			>
				<button
					onClick={() => setShowOverlay(true)}
					className="flex flex-row gap-6 items-center"
				>
					<img
						className="h-10 w-10 rounded-full"
						src={`${process.env.REACT_APP_IMAGES_SERVER}/imgs/users/${member.user_id}.jpg`}
						alt={`${
							member.first_name || member.username
						}'s profile`}
					/>
					<span className="text-[15px]">
						{member.username ||
							member.first_name ||
							member.last_name ||
							'-'}
					</span>
				</button>

				{/* ProfileModal usage */}
				{showOverlay && (
					<ProfileModal
						showOverlay={showOverlay}
						setShowOverlay={setShowOverlay}
					>
						<MemberModal member={member} />
					</ProfileModal>
				)}
			</div>
		)
	}

	const members = data?.members
	const admins = data?.admins
	return (
		<div
			className={`flex flex-col font-sans w-full pr-2 items-center no-scroll-bars relative ${
				isLoading ? 'pb-14' : ''
			}`}
		>
			<div className="flex flex-col gap-12 w-full">
				<div>
					<div className=" px-1 grid grid-cols-3 gap-2  items-center pb-4 text-[15px]  w-full">
						<div className="font-semibold">
							<AdminIcon />
						</div>
						<div className="pl-1">ADMINS</div>
					</div>

					<div className="flex flex-col  gap-3 items-left w-full">
						{!(isLoading && page == 1) &&
							admins &&
							admins.map((member, i) => (
								<Member
									lastChildRef={null}
									key={i}
									member={member}
								/>
							))}
					</div>
				</div>

				<div>
					<div className=" px-1 grid grid-cols-3 gap-2  items-center pb-4 text-[15px]  w-full">
						<div className="font-semibold">
								<MembersIcon className="h-5 w-5" />
						</div>
						<div className="whitespace-nowrap">{total_members} MEMBERS</div>

							<div className="flex flex-row justify-end">
                                <div>
                                sh
                                </div>
                                </div>
					</div>

					<div className="flex flex-col  gap-3 items-left w-full">
						{!(isLoading && page == 1) &&
							members &&
							members.map((member, i) => (
								<Member
									lastChildRef={
										i === members.length - 20
											? lastChildRef
											: null
									}
									key={i}
									member={member}
								/>
							))}
						{isLoading && (
							<div className="pt-20 absolute bottom-0 left-0 right-0 flex justify-center items-center">
								<Spinner />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
})

export default MemberList
