import React from 'react'
import SearchBar from './SearchBar'
import * as tapi from '@api/telegram'
import { SidebarIcon, PinIcon, UnpinIcon, SearchIcon } from '../assets'
import { observer } from 'mobx-react-lite'
import telegramStore from '@store/telegram'
import telegram from '../../../stores/telegram'
import { toJS } from 'mobx'
const iconClassname = 'w-6 h-6'

const Header = ({ setIsDetailsOpen, isDetailsOpen }) => {
	const id = telegramStore.activeChat?.id
	const title =
		telegramStore.activeChat?.title || telegramStore.activeChat?.username
	const type = telegramStore.tabType
	// console.log(telegramStore.activeChat)
	const totalMembers = telegramStore.activeChat?.total_members

	const handleSearchChange = (e) => {
		const prevQuery = telegramStore.getMessagesQuery(id)
		// console.log({
		// 	...telegramStore.messagesQuery,
		// 	text: e.target.value,
		// 	page: 1
		// })
		telegramStore.setMessagesQuery(id, {
			...prevQuery,
			text: e.target.value,
			page: 1
		})
	}
	// console.log(Object.fromEntries(toJS(telegramStore.messagesQuery)))
	const addToWl = () => {
		if (type == 'group') {
			tapi.addChildToWatchlist({ telegramId: id })
				.then((res) => console.log(res))
				.catch((e) => console.error(e))
		} else {
			// console.log(id)
			tapi.addParentToWatchlist({ telegramId: id })
				.then((res) => console.log(res))
				.catch((e) => console.error(e))
		}
	}

	const removeFromWl = () => {
		tapi.removeChildFromWatchlist({ telegramId: id })
			.then((res) => console.log(res))
			.catch((e) => console.error(e))
	}

	const imgSrc = type == 'group' ? 'groups' : 'users'

	if (!id) return null

	const HeaderImg = () => {
		if (imgSrc == 'groups') {
			return (
				<div className="relative h-8 w-8">
					<img
						className="h-8 w-8 rounded-full"
						alt={title} // Added alt attribute for accessibility
						src={`${process.env.REACT_APP_IMAGES_SERVER}/imgs/groups/${id}.jpg`}
					/>
					<span
						className={`absolute bottom-0 right-0 h-2 w-2 rounded-full ${
							telegramStore.activeChat?.active
								? 'bg-green-500'
								: 'bg-red-500'
						}`}
					></span>
				</div>
			)
		} else {
			return (
				<img
					className="h-10 w-10 rounded-full"
					src={`${process.env.REACT_APP_IMAGES_SERVER}/imgs/${imgSrc}/${id}.jpg`}
					alt={`${title}`} // Remember to add alt text for accessibility
				/>
			)
		}
	}

	const SubHeader = () => {
		if (imgSrc == 'groups') {
			return (
				<div className="text-sm opacity-60">{totalMembers} members</div>
			)
		} else {
			return (
				<div className="text-sm opacity-60">
					{telegramStore.activeChat.groupDetails?.length} group(s)
				</div>
			)
		}
	}

	return (
		<div
			id="tg-header"
			className="p-3 shadow-md items-center text-center  inner-bg-color h-[50px] flex flex-row justify-between "
		>
			{' '}
			{/* Updated colors */}
			<div className="flex flex-row gap-4 items-center">
				<div className="flex-shrink-0 h-10 w-10">
					<HeaderImg />
				</div>
				<div className="flex flex-col gap items-start">
					<div className="text-md font-semibold">{title}</div>
					<SubHeader />
				</div>
				<div className="text-sm opacity-60">
					{telegramStore.activeChat.id}
				</div>
			</div>
			<div
				id="tg-header-filters"
				className="flex flex-row  items-center justify-end "
			>
				<SearchBar
					text={telegramStore.getMessagesQuery(id)?.text}
					onChange={handleSearchChange}
				/>
				<div className="flex flex-row gap-2 items-center">
					{telegramStore.groupIsWatchlisted(
						telegramStore.activeChat.id
					) ? (
						<button onClick={removeFromWl}>
							{' '}
							<UnpinIcon className={iconClassname} />{' '}
						</button>
					) : (
						<button onClick={addToWl}>
							<PinIcon className={iconClassname} />
						</button>
					)}
					{/* <div className="text-xs font-semibold">Links </div> */}
					<button
						onClick={() => setIsDetailsOpen((prev) => !prev)}
						className="text-xl font-semibold"
					>
						<SidebarIcon
							fill={isDetailsOpen ? '#5eb5f7' : '#768C9E'}
							className={iconClassname}
						/>
					</button>
				</div>
			</div>
		</div>
	)
}

export default React.memo(observer(Header))
