import { useState, useRef, useCallback, useEffect } from 'react'
import * as helpers from '@api/helpers'

function useInfiniteScroll(apiCallOptions) {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(1)
  const observer = useRef()

  const fetchData = useCallback(
    (options = {}) => {
      let isError = false
      const query = { page, ...apiCallOptions.getQuery(), ...options }
      apiCallOptions
        .apiCall(query)
        .then((response) => {
          const newData = response.data.data
          newData.page = page

          if (data) {
            // console.log('new data', data, newData)
            setData((prev) => ({
              ...newData,
              [apiCallOptions.resultKey]: helpers.appendNewData(
                data[apiCallOptions.resultKey],
                newData[apiCallOptions.resultKey]
              )
            }))
          } else {
            setData(newData)
          }
        })
        .catch((e) => {
          isError = true
          console.error(e)
        })
        .finally(() => {
          if (!isError) setIsLoading(false)
        })
    },
    [apiCallOptions, data, page]
  )

  const lastChildRef = useCallback(
    (node) => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver((entries) => {
        // console.log(page)
        if (entries[0].isIntersecting && data?.hasNext && !isLoading) {
          setPage((prevPage) => {
            const nextPage = prevPage + 1
            if (fetchData) {
              setIsLoading(true)
              fetchData({ page: nextPage, ...apiCallOptions.getQuery() }, () =>
                setIsLoading(false)
              )
            }
            return nextPage
          })
        }
      })

      if (node) observer.current.observe(node)
    },
    [isLoading, data?.hasNext]
  )

  useEffect(() => {
    // Reset data and page when the query changes.
    // Note: This does not need to immediately call fetchData, as the page reset will trigger it.
    setData(null);
    setPage(1);
  }, [apiCallOptions.getQuery()[apiCallOptions.queryKey]]);
  
  useEffect(() => {

    // This effect should only fetch data when `page` is set to 1 due to a query change,
    // or when it increments due to infinite scrolling.
    // Add a condition to avoid fetching data on initial mount (if necessary).
    if (page === 1 && (!data)) {
      fetchData();
    }
  }, [page, fetchData, data]);
  


  return { isLoading, lastChildRef, page, setPage, data, setData }
}

export default useInfiniteScroll
