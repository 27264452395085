import React, { useCallback, useEffect, useRef, useState } from 'react'
// import Message from './components/Message'
import * as helpers from '@api/helpers'
import { observer } from 'mobx-react-lite'
import telegramStore from '@store/telegram'
import Spinner from '@components/Spinner'

const ScrollWrapper = observer(
	({
		data,
		triggerAutoScrollBottom,
		triggerAutoScrollTop,
		chatLoading,
		query,
		children
	}) => {
		const scrollContainerRef = useRef(null)
		const [scrollPosition, setScrollPosition] = useState(0)
		const [distanceToBottom, setDistanceToBottom] = useState(0)

		useEffect(() => {
			if (!data?.length) return

			if (scrollContainerRef.current) {
				scrollContainerRef.current.scrollTop =
					scrollContainerRef?.current?.scrollHeight
			}
		}, [triggerAutoScrollBottom, chatLoading])

		// Auto scroll on prev position when user fetches new page of data
		useEffect(() => {
			if (!data?.length || query?.page < 2) return
			helpers.autoScroll(
				scrollContainerRef.current,
				scrollContainerRef.current.scrollHeight - scrollPosition
			)

			// helpers.scrollToMessageWithId(
			//   lastMessage,
			//   'tg-scrollwrapper-wrapper-outer',
			//   'instant'
			// )
		}, [triggerAutoScrollTop])

		/**
		 * Effect to listen for user scrolling
		 * -- Stores current first visible project
		 * -- Appends new found pending projects when user goes on top
		 *
		 * */
		useEffect(() => {
			const func = (event) => {
				// if (initialRender < 2 && historyStore.getDirection() == historyStore.BACK)
				//   return
				if (scrollContainerRef.current) {
					const node = scrollContainerRef.current
					const distanceToBottom =
						node.scrollHeight - node.clientHeight - node.scrollTop
					setDistanceToBottom(distanceToBottom)
				}
				setScrollPosition(scrollContainerRef?.current?.scrollHeight)
			}

			func()

			scrollContainerRef?.current?.addEventListener('scroll', func)

			return () =>
				// console.info('Exiting scroll effect')
				scrollContainerRef?.current?.removeEventListener('scroll', func)
		}, [data])

		useEffect(() => {
			if (distanceToBottom < 100) {
				if (scrollContainerRef.current) {
					scrollContainerRef.current.scrollTop =
						scrollContainerRef?.current?.scrollHeight
				}
			}
		}, [data])

		return (
			<div
				className="flex-1 overflow-y-auto p-4 relative "
				id="tg-scrollwrapper-wrapper-outer"
				ref={scrollContainerRef}
			>
				<div
					className="flex-grow gap-2 overflow-y-scroll  break-all no-scroll-bars w-full"
					id="tg-scrollwrapper-wrapper-inner"
				>
					{' '}
					{/* {children} */}
					{chatLoading ? <Spinner /> : children}
				</div>
			</div>
		)
	}
)

export default ScrollWrapper
