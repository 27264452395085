import React, { useCallback, useEffect, useRef, useState } from 'react'
import Message from '@tg/components/Message'
import * as helpers from '@api/helpers'
import { observer } from 'mobx-react-lite'
import telegramStore from '@store/telegram'
import Spinner from '@components/Spinner'
import Group from '@tg/components/Group'
import HomeMessage from '../../components/HomeMessage'

const MessageWrapper = observer(({ data, lastChildRef }) => {
	return data.length ? (
		data.map((message, i) => {
			const showUsername =
				i === 0 || data[i - 1].user?.username !== message.user?.username

			const showImage =
				i === data.length - 1 ||
				data[i + 1].user?.username !== message.user?.username
			return (
				<div
					key={message._id}
					className={`${
						showUsername ? 'mt-3' : 'pt-[3px]'
					} flex flex-row gap-5 items-end `}
					id={message._id}
				>
					<Message
						showImage={showImage}
						message={message}
						showUsername={showUsername}
						messageRef={i === 6 ? lastChildRef : null}
						group={true}
					/>
				</div>
			)
		})
	) : (
		<div><HomeMessage /></div>
	)
})

export default MessageWrapper
