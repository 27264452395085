import React, { useState, useRef, useEffect } from 'react';
import { SearchIcon } from '../assets';

function SearchBar({ text, onChange }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const inputRef = useRef(null); // Create a ref for the input element

  useEffect(() => {
    // Focus the input when isExpanded changes to true
    if (isExpanded && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isExpanded]); // Dependency array includes isExpanded

  return (
    <div className="flex items-center justify-between px-4 py-2 gap-2">
      <div className={`flex items-center transition-all duration-300 ease-in-out ${isExpanded ? 'w-64' : 'w-0'}`}>
        <input
          ref={inputRef} // Assign ref to the input
          type="text"
          placeholder="Search"
          value={text || ''}
          onChange={onChange}
          className={`flex-grow p-2 bg-[#242f3d] text-white placeholder-gray-400 Squad-font placeholder:text-sm placeholder:pl-2 rounded-[50px] text-sm border-none overflow-hidden transition-all duration-300 ease-in-out ${isExpanded ? 'opacity-100' : 'opacity-0'}`}
          onFocus={() => setIsExpanded(true)}
          onBlur={() => setIsExpanded(false)}
        />
      </div>
      <button
        className="flex items-center justify-center rounded-full focus:outline-none"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <SearchIcon />
      </button>
    </div>
  );
}

export default SearchBar;
