import React, { useEffect, useState } from 'react'
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate
} from 'react-router-dom'
import { ProvideAuth, fetchSession } from '@hooks/useAuth'

import * as socket from '@api/socket'

// Components
import { TelegramPlayground, Layout, Admin } from '@views/index'
// import useAuth from './hooks/useAuth'
import telegramStore from '@store/telegram'
import historyStore from '@store/history'

function App() {
	const [render, setRender] = useState(true)

	telegramStore.setHistoryStore(historyStore)
	const [state, setState] = useState({
		socket: socket,
		user: null
	})

	useEffect(() => {
		fetchSession()
			.then((res) => {
				setState(prevState => ({
					...prevState,
					user: res.data.data
				}));
				setRender(true);
			})
			.catch((e) => {
				console.error('ERROR ON LOAD', e);
			});
		// Dependency array should ensure fetchSession is called once unless explicitly needed to refetch
	}, []);

	return render ? (
		<Router>
			{/* <ToastContainer /> */}
			<ProvideAuth state={state}>
				<Routes>
					{/* Registered Routes */}
					<Route path="/" element={<Layout />}>
						<Route
							path="/"
							element={<TelegramPlayground />}
							exact
						/>
					</Route>
				{/* Admin */}
                <Route path="/admin/*" element={<Admin />} />
				</Routes>
			</ProvideAuth>
		</Router>
	) : (
		'loading'
	)
}

export default App
