import React, { useState, useEffect } from 'react'
import Web3 from 'web3'
import * as aapi from '@api/auth'
import useAuth from '@hooks/useAuth'

const MetaMaskLogin = () => {
	const auth = useAuth()
	const [web3, setWeb3] = useState(null)
	const [accounts, setAccounts] = useState([])
	const [isMetaMaskInstalled, setIsMetaMaskInstalled] = useState(false)
	const [message, setMessage] = useState(null)
	// const [connecte]
	const [pendingSignature, setPendingSignature] = useState(false)
	const [loggedIn] = useState(false)

	useEffect(() => {
		if (!accounts?.length) return
		aapi.getMessage({ account: accounts[0] })
			.then((res) => setMessage(res.data.data))
			.catch((e) => console.error(e))
	}, [accounts?.length])

	useEffect(() => {
		if (window.ethereum) {
			setIsMetaMaskInstalled(true)
			const web3Instance = new Web3(window.ethereum)
			setWeb3(web3Instance)
		} else {
			alert('Please install MetaMask to use this feature!')
		}
	}, [])

	const connectWallet = async () => {
		try {
			const accounts = await window.ethereum.request({
				method: 'eth_requestAccounts'
			})
			setAccounts(accounts)
			setPendingSignature(true)
			await sign()
		} catch (error) {
			console.error('Error connecting to MetaMask', error)
		}
	}

	const sign = async () => {
		try {
			console.log(message)
			const signature = await window.ethereum.request({
				method: 'personal_sign',
				params: [message, accounts[0]]
			})

			console.log(signature)
			const data = {
				signature,
				account: accounts[0]
			}
			aapi.login(data)
				.then((res) => {
					setMessage(null)
					setPendingSignature(false)
					console.log(res)
					auth.setUser(res.data.user)
				})
				.catch((e) => console.error(e))
		} catch (error) {
			console.error('sign - ', error)
		}
	}

	if (!isMetaMaskInstalled) return <p>MetaMask is not installed.</p>
	if (auth.user) return <h3 className='font-bold '>{auth.user.address.substring(0, 5)}...</h3>
	if (!accounts?.length) return <button onClick={connectWallet}>Connect</button>
	return <button onClick={sign}>Sign</button>
	// if (accounts.length > 0 || auth.user) return <h3>Connected.</h3>
	

	// return <button onClick={connectWallet}>Connect</button>

	// return (
	// 	<div>
	// 		auth.user ? (
	// 				<div>
	// 					{accounts.length > 0 ? (
	// 						<h3>Connected.</h3>
	// 					) : (
							
	// 					)}
	// 				</div>
	// 			) : (
					
	// 			)
	// 	</div>
	// )
}

export default MetaMaskLogin
