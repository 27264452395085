import React, { useEffect, useState } from 'react';
import telegramStore from '@store/telegram'
import { observer } from 'mobx-react-lite'
import ImageLoader from '@components/ImageLoader'

const SideGroup = observer((props) => {
  if (!props.group) return null;

  const lastMsg = telegramStore.getLastMessageOfChat(props.group.id)
  return (
    <button
      id={`sidegroup_${props.group.id}`}
      className="flex items-center p-4 hover:bg-gray-700 cursor-pointer w-full h-[80px]"
      onClick={() => props.onClick(props.group)}
    >
      <div className="flex-shrink-0 h-10 w-10">
        {/* Contact image */}
        <img
          className="h-10 w-10 rounded-full"
          src={`${process.env.REACT_APP_IMAGES_SERVER}/imgs/groups/${props.group.id}.jpg`}
          alt={`${props.group.title}`} // Remember to add alt text for accessibility
        />
        {/* <ImageLoader /> */}
      </div>

      <div className="ml-4 flex flex-col items-start text-left overflow-hidden">
        <div className="text-sm font-medium text-white truncate">
          {props.group.title}
        </div>
        <div className="text-xs font-medium text-white truncate">
          {props.group.username || ''}
        </div>
        <div className="text-sm text-gray-400 truncate">{new Date(lastMsg?.msg_timestamp * 1000).toString().substring(16,21)} {lastMsg?.text}</div>
      </div>
    </button>
  )
})

export default SideGroup;
