import { toJS } from "mobx"

const SideParent = (props) => {

  // console.log('SIDE PARENT ' ,toJS(props.parent))
  if (!props.parent) return
    return (
      <button
        className="flex items-center p-4 hover:bg-gray-700 cursor-pointer w-full"
        onClick={() => props.onClick(props.parent, props.tabType)}
      >
        <div className="flex-shrink-0 h-10 w-10">
          <img
            className="h-10 w-10 rounded-full"
						src={`${process.env.REACT_APP_IMAGES_SERVER}/imgs/users/${props.parent?.id}.jpg`}
          />
        </div>
  
        <div className="ml-4 flex flex-col items-left items-start">
          <div className="text-sm font-medium text-white">
            {props.parent?.username || '-'}
          </div>{' '}
          {/* Text color updated */}
          {/* Text color updated */}
        </div>
      </button>
    )
  }
  
  export default SideParent
  