import React, { useCallback, useEffect, useRef, useState } from 'react'
import MessageWrapper from './MessageWrapper'
import * as tapi from '@api/telegram'
import * as helpers from '@api/helpers'
import ChatDetails from './components/ChatDetails'
import Header from './components/Header'
import ChatArea from './ChatArea'
import Joins from './components/Joins'
import Activity from './Activity'
import { observer } from 'mobx-react-lite'
import telegramStore from '@store/telegram'

const tabComponents = {
	'activity': Activity,
	'profiles': Joins,
	'chat': ChatArea,
	'mygroups': ChatArea,
	'myprofiles': Joins
}



const ChatLayout = observer(() => {
	const [isDetailsOpen, setIsDetailsOpen] = useState(true)

	const tabProps = {
		'profiles': { id: telegramStore.activeChat?.id  },
		'mygroups': {   },
		'myprofiles': { watchlist: true, id: telegramStore.activeChat?.id  },
	  };

	useEffect(() => {
		if (!telegramStore.activeChat) {
			telegramStore.setActiveDetails(null)
			return
		}
		// TODO , UPDATE MEMBERS ON SCROLL
		if (['chat', 'mygroups'].includes(telegramStore.activeTab?.key)) {
			// tapi.members({ groupId: telegramStore.activeChat.id })
			// 	.then((res) => telegramStore.setActiveDetails(res.data.data))
			// 	.catch((e) => console.error(e))
		} else if (
			['profiles', 'myprofiles'].includes(telegramStore.activeTab?.key)
		) {
			// console.log('requesting')
			// tapi.parentGroups({ parentId: telegramStore.activeChat.id })
			// .then((res) => telegramStore.setActiveDetails(res.data.data))
			// .catch((e) => console.error(e))
			
		}
	}, [telegramStore.activeChat])

	
	const renderTab = () => {
		const activeKey = telegramStore.activeTab.key;
		const TabComponent = tabComponents[activeKey];
		const props = tabProps[activeKey] || {};
	  
		return TabComponent ? <TabComponent {...props} /> : <div>Tab not recognized</div>;
	  }

	return (
		<div
			id="tg-chat-layout"
			className="w-full h-full flex flex-row w-full relative"
		>
			<div className="flex flex-col w-full h-full">
				<Header
					isDetailsOpen={isDetailsOpen}
					setIsDetailsOpen={setIsDetailsOpen}
					type={telegramStore.tabType}
				/>
				{renderTab()}
			</div>
			{isDetailsOpen && telegramStore.activeChat ? (
				<ChatDetails
					activeTab={telegramStore.activeTab}
					chat={telegramStore.activeChat}
				/>
			) : null}
			{/* <ChatArea activeChat={activeChat} data={data} /> */}
		</div>
	)
})

export default ChatLayout
