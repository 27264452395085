import React, {
	useState,
	useEffect,
	useRef,
	useCallback,
	useContext
} from 'react'
import OuterSidebar from '../Layout/OuterSidebar'
import Sidebar from './Sidebar'
import * as tapi from '@api/telegram'
import { observer } from 'mobx-react-lite'
import telegramStore from '@store/telegram'
import useTelegramHook from './hooks/useTelegramHook'
import ChatLayout from './ChatLayout'
import Navbar from '../../components/Navbar'
import InnerLayout from '../Layout/InnerLayout'
import historyStore from '../../stores/history'
import { toJS } from 'mobx'

const TelegramPlayground = observer(() => {
	const { setActiveChat, setActiveTab } = useTelegramHook()

	useEffect(() => {
		document.addEventListener('mouseup', function (event) {
			if (event.button === 4) {
				const state = historyStore.next()
				if (state) {
					telegramStore.setActiveDetails(state.details)
					telegramStore.setActiveChat(state.chat)
					telegramStore.setActiveTab(state.tab)
					telegramStore.setActiveChild(state.child)
				}
			}
		})

		window.history.pushState(null, document.title, window.location.href)
		window.addEventListener('popstate', function (event) {
			let dec = true
			if (historyStore.isLastPosition()) {
				historyStore.pushLast({
					tab: telegramStore.getActiveTab(),
					chat: telegramStore.getActiveChat(),
					details: telegramStore.getActiveDetails(),
					child: telegramStore.getActiveChild()
				})
				// dec = false
			}

			const previousState = historyStore.prev(dec)

			console.log(toJS(previousState))

			if (previousState) {
				telegramStore.setActiveDetails(previousState.details)
				telegramStore.setActiveChat(previousState.chat)
				telegramStore.setActiveTab(previousState.tab)
				telegramStore.setActiveChild(previousState.child)
			}
			window.history.pushState(null, document.title, window.location.href)
		})
	}, [])

	// useEffect(() => {
	// 	if (historyStore.stackLength <= 0 || historyStore.currentPosition == historyStore.stackLength - 1) {
	// 		historyStore.push({
	// 			tab: telegramStore.getActiveTab(),
	// 			chat: telegramStore.getActiveChat(),
	// 			details: telegramStore.getActiveDetails(),
	// 			child: telegramStore.getActiveChild()
	// 		})
	// 	}
	// }, [telegramStore.activeChat, telegramStore.activeTab])

	const onOuterOptionClick = (tab) => {
		if (tab?.key == telegramStore.getActiveTab()?.key) return
		historyStore.push({
			tab: telegramStore.getActiveTab(),
			chat: telegramStore.getActiveChat(),
			details: telegramStore.getActiveDetails(),
			child: telegramStore.getActiveChild()
		})

		telegramStore.setActiveDetails(null)
		telegramStore.setActiveChat(null)
		telegramStore.setActiveTab(tab)
		telegramStore.setActiveChild(null)
	}

	return (
		<div className="p-1 h-full w-full  scrollbar-thin scrollbar-webkit overflow-auto">
			<div className=" top-2 right-2 z-50">
				<Navbar />
			</div>
			<div
				id="tg-main-layout"
				className="flex h-[93%] bg-[#1f2936] relative text-white rounded-lg "
				style={{
					boxShadow:
						'inset 0 -3em 3em rgb(0 0 0 / 30%), 0 0 0 2px black, 0.3em 0.3em 1em rgb(0 0 0 / 60%)'
				}}
			>
				<div className="flex h-full w-full pt-4">
					<div className="flex flex-row h-full">
						<OuterSidebar onOuterOptionClick={onOuterOptionClick} />
					</div>
					<InnerLayout />
				</div>
			</div>
		</div>
	)
})

export default TelegramPlayground
