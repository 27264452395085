import React, { useState, useEffect } from 'react';
import Login from '../Login';
import useAuth from '../../hooks/useAuth';

const Navbar = () => {

    const auth = useAuth();

    return (
        <div className='h-[60px] text-white flex flex-row gap-4 w-full items-center bg-dark-blue p-4 shadow-lg'>
            <div className='flex flex-grow text-white text-lg font-bold'>
                <a href="/" className="hover:text-light-blue">Logo</a>
            </div>
            <div className='text-white flex justify-center'>
                <input 
                    type="text" 
                    placeholder="Search..." 
                    className="bg-dark-blue-light text-white border border-light-blue rounded-full px-4 py-1 focus:outline-none focus:border-light-blue-dark w-[300px]"
                />
            </div>
            <div>
                <Login />
            </div>
        </div>
    );
}

export default Navbar;
