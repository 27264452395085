const HomeMessage = ({ text = 'Select a group to view messages'}) => {
	return (
		<div id="home-message" className="flex flex-row items-center h-[95%]  w-full h-full main-bg-color">
			<div className="flex flex-col items-center w-full ">
				<div className="p-2 text-center mb-5 border-1 rounded-[50px] inner-bg-color">
					<div className="flex flex-row text-center items-center justify-center font-bold px-3">
						{text}
					</div>
				</div>
			</div>
		</div>
	)
}


export default HomeMessage