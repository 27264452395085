import {
  Dexscreener,
  Dextools,
  Discord,
  Docs,
  Github,
  Linktree,
  Magiceden,
  Medium,
  Mirror,
  Opensea,
  Telegram,
  Website,
  Gitbook,
  Contract
} from './index'

// import WebsiteIcon from '../assets/icons/WebsiteIcon.jsx'


export const IconMap = {
  contract: (props) => <Contract {...props} />,
  discord: (props) => <Discord {...props} />,
  github: (props) => <Github {...props} />,
  website: (props) => <Website {...props} />,
  dexscreener: (props) => <Dexscreener {...props} />,
  dextools: (props) => <Dextools {...props} />,
  linktr: (props) => <Linktree {...props} />,
  opensea: (props) => <Opensea {...props} />,
  telegram: (props) => <Telegram {...props} />,
  magiceden: (props) => <Magiceden {...props} />,
  medium: (props) => <Medium {...props} />,
  mirror: (props) => <Mirror {...props} />,
  docs: (props) => <Docs {...props} />,
  gitbook: (props) => <Gitbook {...props} />,
};


export default IconMap