import React from 'react';

const Unpin = ({ className = 'w-7 h-7' }) => {
  const svgStyle = {
    fill: '#768C9E', // Change this to the color you want
    fillOpacity: 1,
    stroke: 'none',
    strokeWidth: 4,
    strokeMiterlimit: 4,
    strokeDasharray: 'none',
    strokeOpacity: 1,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-3 -3 36.00 36.00"
      version="1.1"
      id="svg822"
      className={className}
      transform="rotate(90)"

    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0">
        <rect x="-3" y="-3" rx="18" fill="#768C9E" strokeWidth="0"></rect>
      </g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#CCCCCC"
        strokeWidth="3.84"
      >
        <path
          style={svgStyle}
          d="M 19.355469 3.5859375 L 13.210938 9.7304688 L 12.556641 9.078125 C 13.347736 7.524045 13.261719 6.3046875 13.261719 6.3046875 L 11.957031 5 L 5 11.957031 L 6.3046875 13.261719 C 6.3046875 13.261719 7.5262508 13.349924 9.0800781 12.558594 L 9.7304688 13.210938 L 3.8007812 19.142578 L 5.2148438 20.556641 L 20.771484 5 L 19.355469 3.5859375 z M 20.322266 11.945312 C 19.687749 11.95014 18.209443 12.081186 16.533203 13.054688 L 16.058594 12.578125 L 12.578125 16.058594 L 13.052734 16.53125 C 11.75378 18.76502 11.957031 20.652344 11.957031 20.652344 L 13.695312 22.390625 L 17.173828 18.912109 L 23.261719 25 L 25 25 L 25 23.261719 L 18.912109 17.173828 L 22.390625 13.695312 L 20.652344 11.957031 C 20.652344 11.957031 20.533771 11.943703 20.322266 11.945312 z "
        ></path>
      </g>
    </svg>
  );
};

export default Unpin;
